@import '~normalize.css';
@import url('https://fonts.googleapis.com/css?family=Lora:400,400i,700|Roboto:100,300,300i,400,500|Roboto+Condensed:300,400,700|Merriweather:400');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 1.3;
  color: #333;
}

input, textarea, select, button{
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 0.04rem;
  line-height: 1.5;
}

html, body, #root{
  height: 100%;
  font-size: 1rem;
  letter-spacing: 0.03rem;
}

h1 {
  font-weight: 300;
  font-size: 96px;
  letter-spacing: -0.0156rem;
}

h2 {
  font-weight: 300;
  font-size: 60px;
  letter-spacing: -0.0083rem;
}

h3{
  font-weight: 400;
  font-size: 48px;
  letter-spacing: 0;
}

h4{
  font-weight: 400;
  font-size: 34px;
  letter-spacing: 0.0073rem;
}

h5{
  font-weight: 400;
  font-size: 24px;
  letter-spacing: 0;
}

h6{
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.0075rem;
}

ul{
  padding:0;
}

*, :after, :before{
  box-sizing: border-box;
}
